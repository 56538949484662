define("discourse/plugins/discourse-legal-tools/discourse/templates/connectors/admin-user-controls-after/download-all-wrapper", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if showAdminUserExport}}
    {{d-button action="exportAdminUserArchive"
               actionParam=model
               label="user.download_archive.button_text"
               icon="download"}}
  {{/if}}
  
  */
  {
    "id": "WcYarVCJ",
    "block": "[[[41,[30,0,[\"showAdminUserExport\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"action\",\"actionParam\",\"label\",\"icon\"],[\"exportAdminUserArchive\",[30,0,[\"model\"]],\"user.download_archive.button_text\",\"download\"]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `showAdminUserExport` property path was used in the `discourse/plugins/discourse-legal-tools/discourse/templates/connectors/admin-user-controls-after/download-all-wrapper.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.showAdminUserExport}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `model` property path was used in the `discourse/plugins/discourse-legal-tools/discourse/templates/connectors/admin-user-controls-after/download-all-wrapper.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-legal-tools/discourse/templates/connectors/admin-user-controls-after/download-all-wrapper.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});